import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FilePlusIcon, ListBulletIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import useWindowSize from '../../hooks/useWindowSize';
import SideBarBtn from '../ui-elements/SideBarBtn';

interface SideBarProps {
    admin: boolean;
    sitecode: string;
    isSideBar: boolean;
    setIsSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    isAnimating: boolean;
    setIsAnimating: React.Dispatch<React.SetStateAction<boolean>>;
}

interface isActive {
    isActive: boolean;
}

const SideBar = ({
    admin,
    sitecode,
    isSideBar,
    setIsSideBar,
    isAnimating,
    setIsAnimating,
}: SideBarProps) => {
    const navStyle = ({ isActive }: isActive) => {
        return isActive
            ? ` bg-secondcolor py-2  pl-4 font-bold ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`
            : `  py-2 pl-4 font-bold text-secondcolor hover:bg-secondcolor  ${admin ? 'bg-adminbasecolor hover:text-adminbasecolor' : 'bg-basecolor hover:text-basecolor'}`;
    };

    const [width] = useWindowSize();
    const [iconState, setIconState] = useState('');
    const [widthState, setWidthState] = useState('');

    useEffect(() => {
        if (isAnimating) {
            if (width > 768) {
                setWidthState(!isSideBar ? 'translate-x-0 ' : '-translate-x-44 ');
                setIconState(!isSideBar ? '' : 'absolute right-0 ');
            } else {
                setWidthState(isSideBar ? 'translate-x-0 ' : '-translate-x-44 ');
                setIconState(isSideBar ? '' : 'absolute right-0');
            }
        }
    }, [isSideBar, width, isAnimating]);
    useEffect(() => {
        setIsAnimating(false);
    }, []);

    const widthStyle = `fixed left-0 z-50 h-screen w-52 transform text-white transition-transform
           duration-200 ${admin ? 'bg-adminbasecolor' : 'bg-basecolor'}  ${!isAnimating ? (width < 768 ? `-translate-x-44` : `translate-x-0`) : widthState} `;
    const iconStyle = `mr-2 scale-125 z-50 ${!isAnimating ? (!(width < 768) ? '' : 'absolute right-0 ') : iconState}`;

    return (
        <div className={widthStyle}>
            <div className="relative">
                <SideBarBtn
                    isSideBar={isSideBar}
                    setIsSideBar={setIsSideBar}
                    setIsAnimating={setIsAnimating}
                >
                    <div className="block h-screen w-full sm:hidden">
                        <HamburgerMenuIcon className="ml-auto mr-1 mt-[19px] size-6" />
                    </div>
                </SideBarBtn>
            </div>

            <div className="ml-4 mt-[17px] flex flex-row items-center justify-start sm:ml-0 sm:mt-0 sm:flex-col">
                <img
                    src="/survey2.png"
                    alt="ロゴ"
                    className=" mx-1 mt-0 w-10 object-contain sm:mx-auto sm:mt-4 sm:w-1/2"
                />
                <p className="mt-auto text-center text-xl font-bold sm:text-3xl">PICK ONE</p>
            </div>

            <div className="mt-[19px] flex w-full flex-col text-lg sm:mt-10 sm:text-xl ">
                <NavLink
                    to={`${admin ? '/admin/surveys' : '/surveys'}`}
                    state={sitecode}
                    className={navStyle}
                >
                    <div className="flex items-center ">
                        <div className={iconStyle}>
                            <FilePlusIcon />
                        </div>
                        <p className="z-50 w-full">投票の作成</p>
                    </div>
                </NavLink>
                <NavLink
                    to={`${admin ? '/admin/survey_list' : '/survey_list'}`}
                    state={sitecode}
                    className={navStyle}
                >
                    <div className="flex items-center  ">
                        <div className={iconStyle}>
                            <ListBulletIcon />
                        </div>
                        <p className="z-50 w-full"> 投票一覧</p>
                    </div>
                </NavLink>
                {/* <NavLink to={`/signout`} className={navStyle}>
                    <div className="flex items-center  ">
                        <p>サインアウト</p>
                    </div>
                </NavLink> */}
            </div>
        </div>
    );
};

export default SideBar;
