import { ApiConfigure } from '../../ApiConfigure';
import { IApiCommand, IBaseCommandParam } from '..';

import { VoteEntity } from '../../../../cdk/JWebSurveyApp/lib/lambda_functions/apis/vote/VoteRequestValidator';

/**
 * Commnad Params
 */
interface IBaseVoteParam extends IBaseCommandParam {}

interface IGetVoteParam extends IBaseVoteParam {
    // userId: string;
    surveyId: string;
}

interface ISaveVoteParam extends IBaseVoteParam {
    body: VoteEntity;
}

/**
 * Commands
 */

export class GetVoteCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/vote';
    private params: string;

    constructor(param: IGetVoteParam) {
        // クエリパラメータをビルドする
        this.params = `?survey_id=${param.surveyId}`; // &user_id=${param.userId}
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'GET',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}

export class SaveVoteCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)

    private PATH_NAME = '/vote';
    private body: VoteEntity;

    constructor(param: ISaveVoteParam) {
        this.body = param.body;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // Request body
        const body = this.body;

        // config 構築
        const config: RequestInit = {
            method: 'POST',
            ...apiConfigure.fetchConfig,
            body: JSON.stringify(body),
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME;
        return await fetch(url, config);
    }
}
