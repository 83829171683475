import { ApiConfigure } from '../../../ApiConfigure';
import { IApiCommand, IBaseCommandParam } from '../..';
import {
    SurveyEntity,
    UpdateSurveyEntity,
} from '../../../../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';

/**
 * Commnad Params
 */
interface IBaseSurveysParam extends IBaseCommandParam {}

interface IListSurveysParam extends IBaseSurveysParam {
    userId: string;
    sitecodes: string[];
}

interface ISaveSurveysParam extends IBaseSurveysParam {
    userId: string;
    body: SurveyEntity | UpdateSurveyEntity;
}

interface IDeletesSurveyParam extends IBaseSurveysParam {
    surveyId: string;
    userId: string;
}

/**
 * Commands
 */

export class ListSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/admin/surveys';
    private params: string;

    constructor(param: IListSurveysParam) {
        const sitecodeParams = new URLSearchParams();
        param.sitecodes.forEach((sitecode) => sitecodeParams.append('sitecodes', sitecode));
        this.params = `?user_id=${param.userId}&${sitecodeParams.toString()}`;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'GET',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}

export class SaveSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)

    private PATH_NAME = '/admin/surveys';
    private body: SurveyEntity | UpdateSurveyEntity;
    private params: string;

    constructor(param: ISaveSurveysParam) {
        this.body = param.body;
        this.params = `?user_id=${param.userId}`;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // Request body
        const body = this.body;

        // config 構築
        const config: RequestInit = {
            method: 'PUT',
            ...apiConfigure.fetchConfig,
            body: JSON.stringify(body),
        };
        console.log('config', config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}

export class DeleteSurveyCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/admin/surveys';
    private params: string;

    constructor(param: IDeletesSurveyParam) {
        this.params = `?survey_id=${param.surveyId}&user_id=${param.userId}`;
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'DELETE',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}
