/**
 * Api Configure
 */

export interface IApiConfigureParam {
  apiBaseUrl?: string;
  // ID と Token/Pass
  authToken?: string;
  apiKey?: string;
  // Config
  fetchConfig?: RequestInit;
}

export class ApiConfigure {
  // URL パスまでの FQDN or URL
  public apiBaseUrl: string;
  // credentials
  public authToken?: string;
  public apiKey?: string;
  // Config
  public fetchConfig: RequestInit;

  constructor(param: IApiConfigureParam) {
    if (!process.env.REACT_APP_JWEB_URL) {
      throw new Error("jWeb api url is not set at env.");
    }
    // URL は引数に指定する
    this.apiBaseUrl = process.env.REACT_APP_JWEB_URL;
    if (param.apiBaseUrl) this.apiBaseUrl = param.apiBaseUrl;

    // Credentials
    this.apiKey = param.apiKey;
    this.authToken = param.authToken;

    /**
     * Config
     */
    // FetchConfig
    this.fetchConfig = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${param.authToken}`,
        "x-api-key": `${param.apiKey}`,
      },
    };
    // 引数にセットされていれば更新する
    if (param?.fetchConfig)
      this.fetchConfig = { ...this.fetchConfig, ...param.fetchConfig };
  }
}
