import React, { ReactNode } from 'react';

interface SideBarBtnProps {
    isSideBar: boolean;
    setIsSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAnimating: React.Dispatch<React.SetStateAction<boolean>>;
    children: ReactNode;
}

const SideBarBtn = ({ isSideBar, setIsSideBar, setIsAnimating, children }: SideBarBtnProps) => {
    const buttonStyle = 'flex h-full items-start justify-end';
    const handleSideBar = () => {
        setIsAnimating(true);
        isSideBar ? setIsSideBar(false) : setIsSideBar(true);
    };
    return (
        <button
            onClick={handleSideBar}
            className={`absolute w-full ${isSideBar ? buttonStyle : ''}`}
        >
            {children}
        </button>
    );
};

export default SideBarBtn;
