import React, { useState, useEffect } from 'react';
import { useAuth } from '../../features/auth/AuthContext';
import { useJWebApi } from '../../features/api/useJWebApi';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { DeleteSurveyCommand, ListSurveyCommand } from '../../features/api/commands';
import {
    SurveyEntity,
    OptionKey,
} from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import {
    DeleteConfirmModal,
    LoadingModal,
    TitlePart,
} from '../../components/ui-elements/UiElements';
import ResultPage from '../result/ResultPage';
import SideBar from '../../components/layouts/SideBar';
import SignIn from '../signin/SignIn';
import ListPagePartsPC from '../../components/ui-elements/ListPagePartsPC';
import ListPagePartsSP from '../../components/ui-elements/ListPagePartsSP';
import SideBarBtn from '../../components/ui-elements/SideBarBtn';
export type OptionsRecord = Record<OptionKey, string>;

interface SurveyListPageProps {
    admin: boolean;
}

const SurveyListPage = ({ admin }: SurveyListPageProps) => {
    document.body.style.overflow = 'hidden';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [copy, setCopy] = useState('投票IDをコピー');

    //ステータス表示
    const [nowTime, setNowTime] = useState('');
    useEffect(() => {
        const getCurrentTime = () => {
            const now = new Date();
            const japanOffset = 9 * 60;
            const japanTime = new Date(now.getTime() + japanOffset * 60 * 1000);
            const iso8601Time = japanTime.toISOString();
            setNowTime(iso8601Time);
        };
        getCurrentTime();
        const intervalId = setInterval(() => {
            getCurrentTime();
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    //API
    const { idToken, userId } = useAuth();
    const apiConfigure = new ApiConfigure({
        authToken: idToken,
    });

    const { response, loading, call } = useJWebApi(apiConfigure);
    const { response: resDel, loading: delLoading, call: deleteCall } = useJWebApi(apiConfigure);

    const [isUpdate, setIsUpdate] = useState<number>(0);
    const handleListUpdate = () => {
        setIsUpdate(isUpdate + 1);
    };

    //サイトコード
    const [selectedSitecode, setSelectedSiteCode] = useState<string>('');

    useEffect(() => {
        if (idToken && selectedSitecode) {
            console.table({
                idToken: idToken,
                selectedSitecode: selectedSitecode,
            });
            call(
                new ListSurveyCommand({
                    sitecodes: [selectedSitecode],
                    userId: userId,
                }),
            );
        }
    }, [idToken, resDel, isUpdate, selectedSitecode]);

    function sendDelete(id?: string) {
        if (id) {
            deleteCall(new DeleteSurveyCommand({ surveyId: id, userId: userId }));
        }
    }

    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [deleteTargetId, setDeleteTargetId] = useState<string | undefined>();
    const surveys: SurveyEntity[] = response?.data.surveys;
    const [surveyId, setSurveyId] = useState<string | undefined>();

    const handleItemClick = (surveyId: string | undefined) => {
        setIsModalOpen(true);
        setSurveyId(surveyId);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
    };

    const handleDeleteCloseClick = () => {
        setOpenConfirm(false);
        closeModal();
    };
    const handleModalOnlyClose = () => {
        setOpenConfirm(false);
    };
    const handleDeleteOpenClick = (id: string | undefined) => {
        setOpenConfirm(true);
        setDeleteTargetId(id);
    };

    const handleCopyClick = () => {
        setCopy('クリップボードにコピーしました');
    };
    const handleMouseOut = () => {
        setTimeout(() => {
            setCopy('投票IDをコピー');
        }, 200);
    };

    //キャンセル
    const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
    const handleCancelModal = () => {
        setIsOpenCancelModal(true);
    };
    const handleCancelModalClose = () => {
        setIsOpenCancelModal(false);
    };

    //サイドバー
    const [isSideBar, setIsSideBar] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const { isSignedIn } = useAuth();
    if (!isSignedIn) {
        return <SignIn admin={admin} />;
    }
    return (
        <>
            <LoadingModal open={loading || delLoading} admin={admin} />
            <ResultPage
                isOpen={isModalOpen}
                handleDeleteConfilmOpen={handleDeleteOpenClick}
                onClose={closeModal}
                surveyId={surveyId}
                sitecode={selectedSitecode}
                admin={admin}
                isOpenCancelModal={isOpenCancelModal}
                handleListUpdate={handleListUpdate}
                handleCancelModal={handleCancelModal}
                handleCancelModalClose={handleCancelModalClose}
            />
            <DeleteConfirmModal
                open={openConfirm}
                admin={admin}
                handleConfirm={() => {
                    sendDelete(deleteTargetId);
                }}
                handleClose={handleDeleteCloseClick}
                handleModalOnlyClose={handleModalOnlyClose}
            />

            <div className="overflow-hidden">
                <div className="flex ">
                    <SideBar
                        admin={admin}
                        sitecode={selectedSitecode}
                        isSideBar={isSideBar}
                        isAnimating={isAnimating}
                        setIsSideBar={setIsSideBar}
                        setIsAnimating={setIsAnimating}
                    />
                    <SideBarBtn
                        isSideBar={isSideBar}
                        setIsSideBar={setIsSideBar}
                        setIsAnimating={setIsAnimating}
                    >
                        {/* FadeIn */}
                        <div
                            className={`${isSideBar ? 'animate-fadeIn bg-black opacity-25' : ''}  z-10 block h-full w-full sm:hidden `}
                        />
                    </SideBarBtn>
                    {/* FadeOut */}
                    <div
                        className={`${!isSideBar ? 'animate-fadeOut pointer-events absolute z-10 h-full w-full bg-black opacity-0' : ''} pointer-events-none block sm:hidden`}
                    />
                    <div
                        className={`mr-10 h-dvh w-full translate-x-7 transform transition-transform duration-200  sm:mr-48 sm:translate-x-52`}
                    >
                        <div className="h-full py-8 sm:px-10 sm:py-12 ">
                            <TitlePart
                                title={'投票一覧'}
                                admin={admin}
                                selectedSitecode={selectedSitecode}
                                setSelectedSiteCode={setSelectedSiteCode}
                            />
                            <>
                                {response?.data && (
                                    <>
                                        <ListPagePartsPC
                                            surveys={surveys}
                                            handleItemClick={handleItemClick}
                                            nowTime={nowTime}
                                            handleButtonClick={handleButtonClick}
                                            handleCopyClick={handleCopyClick}
                                            handleMouseOut={handleMouseOut}
                                            copy={copy}
                                        />
                                        <ListPagePartsSP
                                            surveys={surveys}
                                            handleItemClick={handleItemClick}
                                            nowTime={nowTime}
                                            handleButtonClick={handleButtonClick}
                                            handleCopyClick={handleCopyClick}
                                            handleMouseOut={handleMouseOut}
                                            copy={copy}
                                        />
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SurveyListPage;
