import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import DropDown from './DropDown';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    Typography,
} from '@mui/material';

const cancelStyle =
    'm-2 sm:h-10   w-1/2 rounded-full border-2  py-1 text-sm shadow-sm hover:border-yellow-400';

/**
 * ドメインや API に関わらないコンポーネント群
 * コンテキストとは連携する
 */

interface LoadingModalProps {
    open: boolean;
    admin: boolean;
}
export const LoadingModal: React.FC<LoadingModalProps> = ({ open, admin }) => {
    return (
        <Modal
            open={open}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
        >
            {/* Boxコンポーネントをフレックスコンテナとして使用 */}
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex', // フレックスコンテナとして設定
                    alignItems: 'center', // 垂直方向の中心に配置
                    justifyContent: 'center', // 水平方向の中心に配置
                    height: '100%',
                    width: '100%',
                }}
            >
                <div className="flex justify-center" aria-label="読み込み中">
                    <div
                        className={`h-10 w-10 animate-spin rounded-full border-4  border-t-transparent ${admin ? 'border-adminbasecolor' : 'border-basecolor'}`}
                    ></div>
                </div>
            </Box>
        </Modal>
    );
};

interface ConfirmModalProps {
    open: boolean;
    admin: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    handleModalOnlyClose: () => void;
}
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: '10px' } }}>
            <h1 className=" pl-5 pt-4 font-bold">投票の作成</h1>
            <DialogContent>
                <div>
                    <p className=" text-xs sm:text-base sm:leading-tight">
                        入力された内容で投票を作成します。
                        <br />
                        よろしいですか？
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 w-1/2  rounded-full border-2 p-2  py-1  text-sm text-white hover:bg-white sm:h-10  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    作成
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の更新</h1>
            <DialogContent>
                <div>
                    <p className=" text-xs sm:text-base sm:leading-tight">
                        入力された内容で更新します。
                        <br />
                        よろしいですか？
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 w-1/2  rounded-full border-2 p-2  py-1  text-sm text-white hover:bg-white sm:h-10  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    更新
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const UpdateCancelModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の更新</h1>
            <DialogContent>
                <div>
                    <p className=" text-xs sm:text-base sm:leading-tight">
                        更新された内容を保存せずに終了します。
                        <br />
                        よろしいですか？
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleClose} className={cancelStyle}>
                    いいえ
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 w-1/2  rounded-full border-2 p-2  py-1  text-sm text-white hover:bg-white sm:h-10  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    はい
                </button>
            </DialogActions>
        </Dialog>
    );
};

export const DeleteConfirmModal: React.FC<ConfirmModalProps> = ({
    open,
    admin,
    handleClose,
    handleConfirm,
    handleModalOnlyClose,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '10px', width: '400px' } }}
        >
            <h1 className=" pl-5 pt-4 font-bold">投票の削除</h1>
            <DialogContent>
                <div>
                    <p className=" text-xs sm:text-base sm:leading-tight">
                        投票を削除しますが、よろしいですか？
                        <br />
                        <br />
                        この操作を行った後は、回答結果も含めて <br />
                        投票に関するすべてのデータが失われます。
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleModalOnlyClose} className={cancelStyle}>
                    キャンセル
                </button>
                <button
                    onClick={() => {
                        handleConfirm();
                        handleClose();
                    }}
                    className={`m-2 w-1/2  rounded-full border-2 p-2 py-1  text-sm text-white hover:bg-white sm:h-10  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    削除
                </button>
            </DialogActions>
        </Dialog>
    );
};
interface ResultModalProps {
    open: boolean;
    admin: boolean;
    handleClose: () => void;
    text: string;
}
export const ResultModal: React.FC<ResultModalProps> = ({ open, text, handleClose, admin }) => {
    const [copied, setCopied] = useState<boolean>(false);

    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: '10px' } }}>
            <h1 className=" pl-6 pt-4 font-bold">登録完了</h1>
            <DialogContent>
                <p className="text-sm sm:text-base">投票を作成しました。</p>

                <div color="black" className="mb-1 pl-1 pt-5 text-sm sm:text-base">
                    投票ID
                </div>
                <div
                    color="black"
                    className="border-2 px-3 py-1  text-xs sm:pl-3 sm:pr-24 sm:text-base"
                >
                    {text}
                </div>
                <DialogContentText>
                    <Typography></Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(text);
                        setCopied(true);
                    }}
                    className={` m-2 w-1/2 rounded-full  border-2 py-1   text-sm text-white hover:bg-white sm:h-10  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                >
                    {copied ? (
                        <>
                            <CheckIcon />
                            {'コピー完了'}
                        </>
                    ) : (
                        'IDをコピーする'
                    )}
                </button>
                <button onClick={handleClose} className={cancelStyle}>
                    閉じる
                </button>
            </DialogActions>
        </Dialog>
    );
};

interface ResetButtonProps {
    clickHandler: () => void;
}
export const ResetButton: React.FC<ResetButtonProps> = ({ clickHandler }) => {
    return (
        <Button
            onClick={clickHandler}
            type="button"
            variant="contained"
            color="inherit"
            sx={{
                borderRadius: 10,
                padding: '1 2em',
                textTransform: 'none',
                width: '100%',
                boxShadow: 'none',
                backgroundColor: '#F5F7FA',
                '&:hover': {
                    boxShadow: 'none',
                    opacity: 1,
                },
            }}
        >
            リセット
        </Button>
    );
};

interface TitlePartProps {
    title: string;
    admin: boolean;
    selectedSitecode: string;
    setSelectedSiteCode: React.Dispatch<React.SetStateAction<string>>;
}
export const TitlePart = ({
    title,
    admin,
    selectedSitecode,
    setSelectedSiteCode,
}: TitlePartProps) => {
    return (
        <div className=" absolute ml-4 flex h-0  w-full items-center justify-center sm:ml-0">
            <label className="ml-2 whitespace-nowrap text-xl font-bold sm:ml-6 sm:text-2xl ">
                {title}
            </label>
            <div className=" ml-auto mr-4 pr-0 text-end sm:mr-20">
                <DropDown
                    admin={admin}
                    selectedSitecode={selectedSitecode}
                    setSelectedSiteCode={setSelectedSiteCode}
                />
            </div>
        </div>
    );
};
