import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../features/auth/AuthContext';

const SignOut = () => {
    const { signOut } = useAuth();
    const navigate = useNavigate(); // リダイレクトのためのhook

    return (
        <div className="flex h-screen w-screen items-center justify-center">
            <button
                onClick={() => {
                    signOut((path: string) => {
                        navigate(path);
                    });
                }}
                className="h-wit w-fit border-2  p-2 hover:bg-gray-400 hover:text-white"
            >
                サインアウト
            </button>
        </div>
    );
};

export default SignOut;
