import React from 'react';
import { SurveyEntity } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import { convertUtcToJst16 } from '../../utils/DateTimeManager';
import { CopyIcon } from '@radix-ui/react-icons';
import { Tooltip } from 'react-tooltip';

interface ListPagePartsSPProps {
    surveys: SurveyEntity[];
    handleItemClick: (surveyId: string | undefined) => void;
    nowTime: string;
    handleButtonClick: (event: React.MouseEvent<HTMLInputElement>) => void;
    handleCopyClick: () => void;
    handleMouseOut: () => void;
    copy: string;
}

const ListPagePartsSP = ({
    surveys,
    handleItemClick,
    nowTime,
    handleButtonClick,
    handleCopyClick,
    handleMouseOut,
    copy,
}: ListPagePartsSPProps) => {
    document.body.style.overflow = 'hidden';
    return (
        <div className="mt-8 block h-[98%] w-full overflow-y-auto sm:hidden">
            {surveys
                .sort((b, a) => {
                    if (a.createdAt === undefined) return 1;
                    if (b.createdAt === undefined) return -1;
                    return a.createdAt.localeCompare(b.createdAt);
                })
                .map((row: SurveyEntity, index) => (
                    <button
                        className={`mb-3 ml-4 w-[95%]  border-l-4 bg-white  shadow-md  ${
                            nowTime < convertUtcToJst16(row.startDateTime)
                                ? 'border-basecolor '
                                : convertUtcToJst16(row.endDateTime) < nowTime
                                  ? 'border-gray-400 '
                                  : 'border-red-400 '
                        }`}
                        onClick={() => handleItemClick(row.id)}
                        key={index}
                    >
                        {/* 状態 */}
                        <div
                            className={`left ml-2 py-1 text-start text-xs font-bold ${
                                nowTime < convertUtcToJst16(row.startDateTime)
                                    ? ' text-basecolor'
                                    : convertUtcToJst16(row.endDateTime) < nowTime
                                      ? ' text-gray-400'
                                      : ' text-red-400'
                            }`}
                        >
                            {nowTime < convertUtcToJst16(row.startDateTime)
                                ? '期間前'
                                : convertUtcToJst16(row.endDateTime) < nowTime
                                  ? '期間終了'
                                  : '期間中'}
                        </div>

                        {/* 質問 */}
                        <p className="ml-2 pb-2 text-start text-lg font-bold">{row.questionBody}</p>

                        {/* 投票ID */}
                        <div className="ml-2 flex text-start">
                            <p className=" flex-auto text-nowrap text-sm ">{row.id}</p>
                            <div
                                onClick={handleButtonClick}
                                className="  flex w-full items-center justify-center"
                            >
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(row.id || 'Default Text');
                                        handleCopyClick();
                                    }}
                                    data-tooltip-id="copy"
                                    onMouseOut={handleMouseOut}
                                >
                                    <div className=" text-gray-400">
                                        <CopyIcon />
                                    </div>
                                </button>
                                <Tooltip id="copy" place="bottom-end" content={copy} />
                            </div>
                        </div>

                        {/* 開始時間～終了時間 */}
                        <td className=" p-2 ">
                            <p className="whitespace-nowrap text-sm">
                                {convertUtcToJst16(row.startDateTime)
                                    .replace('T', ' ')
                                    .replace(/-/g, '/')}
                            </p>
                        </td>
                        <td>-</td>
                        <td className="p-2">
                            <p className="whitespace-nowrap text-sm">
                                {convertUtcToJst16(row.endDateTime)
                                    .replace('T', ' ')
                                    .replace(/-/g, '/')}
                            </p>
                        </td>
                    </button>
                ))}
        </div>
    );
};

export default ListPagePartsSP;
