// externals
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PlusCircledIcon, TrashIcon } from '@radix-ui/react-icons';
// backends
import {
    SurveyEntity,
    OptionsRecord,
} from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import { SummaryEntity } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/summary/Summary';
import {
    validateText,
    validateDateTime,
} from '../../cdk/JWebSurveyApp/lib/lambda_functions/commons/CommonValidatator';
// apis
import { useJWebApi } from '../../features/api/useJWebApi';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { SaveSurveyCommand } from '../../features/api/commands/admin';
// auth
import { useAuth } from '../../features/auth/AuthContext';
// components
import {
    LoadingModal,
    UpdateModal,
    UpdateCancelModal,
} from '../../components/ui-elements/UiElements';
// etcs
import {
    TWO_HOURS,
    TWO_WEEKS,
    convertUtcToJst16,
    convertJst16ToUtc,
    calculateFutureTimeISO,
} from '../../utils/DateTimeManager';
import { IFullfill } from '../../cdk/JWebSurveyApp/lib/lambda_functions/commons/ResponseBuilder';

// consts
const MIN_OPTION_NUM = 2;
const MAX_OPTION_NUM = 10;
const Now = new Date();

type VoteFormData = {
    id: string;
    questionBody: string;
    description: string;
    options: string[];
    allowMultiSelect: boolean;
    startDateTime: string;
    endDateTime: string;
};

interface UpdatePageProps {
    isItems: SurveyEntity | undefined;
    summarys: SummaryEntity[];
    smryLoading: boolean;
    smryRes: IFullfill<any> | null;
    nowTime: string;
    hiddenCheck: boolean;
    admin: boolean;
    handleVoteOpenClick: (optionKey: `option${number}`) => void;
    handleUpdateFalse: () => void;
    isOpenCancelModal: boolean;
    handleCancelModalClose: () => void;
    handleCancelModal: () => void;
    handlecloseClick: () => void;
    handleResultClose: () => void;
    sitecode: string;
}

const UpdatePage = ({
    isItems,
    handleUpdateFalse,
    admin,
    hiddenCheck,
    isOpenCancelModal,
    handleCancelModalClose,
    handleResultClose,
    sitecode,
}: UpdatePageProps) => {
    //tailwind CSS
    const modalTitle = 'indent-2 text-lg font-bold sm:text-xl border-b-2';
    const textareaStyle = `sm:text-base text-sm pl-2 pt-1 mt-2 w-full border-2 rounded-md focus:outline-none focus:bg-yellow-50 ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;
    const timetitle = 'pl-1 text-xs font-bold sm:pl-0 sm:text-base';
    const timeBody = 'pl-3 mt-2 w-full sm:flex';
    const timeStyle = `sm:text-base text-sm sm:w-44 w-40 rounded-md border-2 px-1 focus:outline-none focus:bg-yellow-50 ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;
    const optionStyle = `sm:text-base text-sm mt-1 h-9 sm:h-10 sm:w-80 w-[92%] resize-none rounded-lg border-2 pl-2 pt-1.5  required:pl-2 focus:bg-yellow-50  focus:outline-none ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;
    const resetStyle =
        'sm:text-base text-xs sm:ml-2 ml-4 rounded-full border-2 px-4 hover:border-yellow-400';
    const errorStyle = 'text-sm text-red-500 ml-1';

    //時間
    const [nowTime, setNowTime] = useState('');
    useEffect(() => {
        const getCurrentTime = () => {
            const now = new Date();
            const japanOffset = 9 * 60;
            const japanTime = new Date(now.getTime() + japanOffset * 60 * 1000);
            const iso8601Time = japanTime.toISOString();
            setNowTime(iso8601Time);
        };
        getCurrentTime();
        const intervalId = setInterval(() => {
            getCurrentTime();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    const beforeStartDateTime = isItems && convertUtcToJst16(isItems.startDateTime) > nowTime;

    //フォーム
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        trigger,
        watch,
    } = useForm<VoteFormData>();
    const { idToken, userId } = useAuth();

    const { response, call, loading } = useJWebApi(
        new ApiConfigure({
            authToken: idToken,
        }),
    );

    const formSubmit = async () => {
        const formData = getValues();
        const allowMultiSelect =
            process.env.REACT_APP_TARGET_ENV === 'Production' ? false : formData.allowMultiSelect;
        const filteredData = Object.fromEntries(
            Object.entries(formData).filter(([key]) => !filterList.includes(key)),
        );
        if ('startDateTime' in filteredData) {
            filteredData.startDateTime = convertJst16ToUtc(filteredData.startDateTime as string);
        }
        if ('endDateTime' in filteredData) {
            filteredData.endDateTime = convertJst16ToUtc(filteredData.endDateTime as string);
        }
        if ('options' in filteredData) {
            // options を構成
            let options: OptionsRecord = {};
            formData.options.forEach((option, index) => {
                options = { ...options, [`option${index + 1}`]: option };
            });
            filteredData.options = options as unknown as string;
        }
        await call(
            new SaveSurveyCommand({
                userId: userId,
                body: {
                    ...filteredData,
                    id: formData.id,
                    allowMultiSelect: allowMultiSelect,
                    createdAt: response?.data.createdAt,
                    updatedAt: response?.data.updatedAt,
                    sitecodes: [sitecode],
                },
            }),
        );
    };
    const handleSubmitModal = async (): Promise<void> => {
        await formSubmit();
        handleUpdateFalse();
    };

    const validateSurveyDateTime = () => {
        const startDateTime = watch('startDateTime');
        const endDateTime = watch('endDateTime');
        if (endDateTime === '' || startDateTime === '') return true;

        return validateDateTime(startDateTime, endDateTime);
    };

    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const handleOpenModal = async () => {
        const isValid = await trigger();
        if (isValid) {
            setIsOpenConfirmModal(true);
        }
    };
    const handleModalOnlyClose = () => {
        setIsOpenConfirmModal(false);
    };

    const option: OptionsRecord = (isItems?.options as OptionsRecord) || false;
    const [currentOptionLength, setCurrentOptionLength] = useState<number>(
        Object.keys(option).length,
    );

    //変更プロパティチェック
    const [filterList, setFilterList] = useState([
        'description',
        'questionBody',
        'startDateTime',
        'endDateTime',
        'options',
    ]);

    //説明比較
    const handleDescriptionCheck = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFilterList((prevFilterList) => {
            if (isItems?.description === event.target.value) {
                return [...prevFilterList, 'description'];
            } else {
                return prevFilterList.filter((item) => item !== 'description');
            }
        });
    };

    //質問比較
    const handleQuestionBodyCheck = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFilterList((prevFilterList) => {
            if (isItems?.questionBody === event.target.value) {
                return [...prevFilterList, 'questionBody'];
            } else {
                return prevFilterList.filter((item) => item !== 'questionBody');
            }
        });
    };

    //開始日時比較
    const [startValue, setStartValue] = useState(
        isItems && convertUtcToJst16(isItems.startDateTime),
    );
    const handleStartValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartValue(event.target.value);
        setFilterList((prevFilterList) => {
            if (isItems && convertUtcToJst16(isItems.startDateTime) === event.target.value) {
                return [...prevFilterList, 'startDateTime'];
            } else {
                return prevFilterList.filter((item) => item !== 'startDateTime');
            }
        });
    };
    const handleStartValueReset = () => {
        return setStartValue('');
    };
    const handleEndValueReset = () => {
        return setEndValue('');
    };

    //終了日時比較
    const [endValue, setEndValue] = useState(isItems && convertUtcToJst16(isItems.endDateTime));
    const handleEndValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndValue(event.target.value);
        setFilterList((prevFilterList) => {
            if (isItems && convertUtcToJst16(isItems.endDateTime) === event.target.value) {
                return [...prevFilterList, 'endDateTime'];
            } else {
                return prevFilterList.filter((item) => item !== 'endDateTime');
            }
        });
    };

    //オプション比較
    const oldOptionsValue: string[] = (isItems && Object.values(isItems.options)) || [];
    //テキスト入力
    const handleOptionCheck = (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFilterList((prevFilterList) => {
            if (oldOptionsValue[index] === event.target.value) {
                return [...prevFilterList, 'options'];
            } else {
                return prevFilterList.filter((item) => item !== 'options');
            }
        });
    };
    //選択肢数
    const countValues = () => {
        setFilterList((prevFilterList) => {
            if (Object.keys(option).length === currentOptionLength) {
                return prevFilterList.filter((item) => item !== 'options');
            } else {
                if (filterList.indexOf('options') === -1) {
                    return prevFilterList.filter((item) => item !== 'options');
                } else {
                    return [...prevFilterList, 'options'];
                }
            }
        });
    };
    //追加ボタン（選択肢）
    const handleAddBtn = () => {
        countValues();
        setCurrentOptionLength(getValues().options.length + 1);
    };
    //削除ボタン（選択肢）
    const handleDeleteBtn = (index: number) => {
        countValues();
        const option = getValues().options;
        setCurrentOptionLength(option.length - 1);
        setValue('options', [...option.filter((_, i) => i !== index)]);
    };

    return (
        <>
            <UpdateModal
                open={isOpenConfirmModal}
                admin={admin}
                handleClose={() => {
                    setIsOpenConfirmModal(false);
                }}
                handleConfirm={handleSubmitModal}
                handleModalOnlyClose={handleModalOnlyClose}
            />
            <UpdateCancelModal
                open={isOpenCancelModal}
                admin={admin}
                handleClose={handleCancelModalClose}
                handleConfirm={handleResultClose}
                handleModalOnlyClose={handleModalOnlyClose}
            />
            <LoadingModal open={loading} admin={admin} />
            {isItems && (
                <form onSubmit={handleSubmit(handleOpenModal)}>
                    {/* ID */}
                    <input type="text" className="hidden" value={isItems.id} {...register(`id`)} />
                    {/* 説明 */}
                    <div className="mt-4 w-full">
                        <p className={modalTitle}>説明</p>

                        <textarea
                            id="description"
                            placeholder={`説明を入力してください`}
                            {...register('description', {
                                validate: validateText,
                            })}
                            onChange={handleDescriptionCheck}
                            className={textareaStyle}
                        >
                            {isItems.description}
                        </textarea>
                        {errors.description && (
                            <p className={errorStyle}>{errors.description?.message}</p>
                        )}
                    </div>
                    {/* 質問 */}
                    <div className="mt-4 w-full">
                        <p className={modalTitle}>質問</p>
                        <textarea
                            id="questionBody"
                            placeholder={`質問を入力してください`}
                            {...register('questionBody', {
                                validate: validateText,
                            })}
                            onChange={handleQuestionBodyCheck}
                            className={textareaStyle}
                        >
                            {isItems.questionBody}
                        </textarea>
                        {errors.questionBody && (
                            <p className={errorStyle}>{errors.questionBody?.message}</p>
                        )}
                    </div>

                    {/* 複数選択可 */}
                    <div className="hidden">
                        {process.env.REACT_APP_TARGET_ENV !== 'Production' && (
                            <div>
                                <label className="flex">
                                    <div className="ml-2 mt-2  ">複数選択可</div>
                                    <input
                                        className="ml-1 mt-2 "
                                        type="checkbox"
                                        {...register('allowMultiSelect')}
                                    ></input>
                                </label>
                            </div>
                        )}
                    </div>

                    {/* 回答期間 */}
                    <div className="mt-4 w-full">
                        <p className={modalTitle}>回答期間</p>
                        <div className={timeBody}>
                            <label htmlFor="start" className={timetitle}>
                                開始日時
                            </label>
                            <span className=" pl-3">
                                <div className="flex">
                                    <div>
                                        <input
                                            id="startDateTime"
                                            type="datetime-local"
                                            value={startValue}
                                            className={timeStyle}
                                            {...register('startDateTime', {
                                                required: '開始日時は必須です。',
                                                validate: validateSurveyDateTime,
                                            })}
                                            onChange={handleStartValueChange}
                                            min={
                                                beforeStartDateTime
                                                    ? calculateFutureTimeISO(Now)
                                                    : convertUtcToJst16(isItems.startDateTime)
                                            }
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleStartValueReset()}
                                        className={resetStyle}
                                    >
                                        リセット
                                    </button>
                                </div>
                                {errors.startDateTime && (
                                    <p className={errorStyle}>{errors.startDateTime?.message}</p>
                                )}
                            </span>
                        </div>

                        <div className={timeBody}>
                            <label htmlFor="end" className={timetitle}>
                                終了日時
                            </label>
                            <span className=" pl-3">
                                <div className="flex">
                                    <div>
                                        <input
                                            id="endDateTime"
                                            type="datetime-local"
                                            value={endValue}
                                            className={timeStyle}
                                            {...register('endDateTime', {
                                                required: '終了日時は必須です。',
                                                validate: validateSurveyDateTime,
                                            })}
                                            onChange={handleEndValueChange}
                                            min={calculateFutureTimeISO(
                                                new Date(watch('startDateTime') || Now),
                                                TWO_HOURS,
                                            )}
                                            max={calculateFutureTimeISO(
                                                new Date(watch('startDateTime') || Now),
                                                TWO_WEEKS,
                                            )}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleEndValueReset()}
                                        className={resetStyle}
                                    >
                                        リセット
                                    </button>
                                </div>
                                {errors.endDateTime && (
                                    <p className={errorStyle}>{errors.endDateTime?.message}</p>
                                )}
                            </span>
                        </div>
                    </div>
                    {/* 選択肢リスト */}
                    <div className="mt-4 w-full pb-12">
                        <div className="flex">
                            <p className={`${modalTitle} flex-1`}>回答結果</p>
                            <p className="ml-auto border-b-2 pr-4 font-bold">
                                {`${isItems.allowMultiSelect ? '複数' : '単一'}  `}
                            </p>
                        </div>
                        <div className="rounded-md pb-4   pl-2  pt-3">
                            {Array.from({ length: currentOptionLength }).map((_, idx) => {
                                const option = Object.entries(isItems.options).sort((a, b) =>
                                    a[0].localeCompare(b[0], undefined, { numeric: true }),
                                );
                                const [key, value] = option[idx] || [];

                                return (
                                    <>
                                        <div key={idx} className="flex items-center">
                                            <textarea
                                                id={`option-${idx}`}
                                                placeholder={`回答 ${idx + 1}`}
                                                {...register(`options.${idx}`, {
                                                    required: '設問は必須です。',
                                                    validate: validateText,
                                                })}
                                                onChange={(event) => handleOptionCheck(idx, event)}
                                                className={optionStyle}
                                            >
                                                {value}
                                            </textarea>
                                            {/* 削除ボタン */}
                                            {hiddenCheck && (
                                                <div>
                                                    {idx > 1 &&
                                                        (idx === currentOptionLength - 1 ||
                                                            currentOptionLength >
                                                                MIN_OPTION_NUM) && (
                                                            <button
                                                                type="button"
                                                                color="error"
                                                                onClick={() => {
                                                                    handleDeleteBtn(idx);
                                                                }}
                                                                tabIndex={-1}
                                                                className={`scale-150 pl-2 pt-3 focus:outline-none ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`}
                                                            >
                                                                <TrashIcon />
                                                            </button>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        {errors.options?.[idx] && (
                                            <p className={errorStyle}>
                                                {errors.options?.[idx]?.message}
                                            </p>
                                        )}
                                    </>
                                );
                            })}

                            {/* 追加ボタン */}
                            {hiddenCheck && (
                                <div>
                                    {currentOptionLength < MAX_OPTION_NUM && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                handleAddBtn();
                                            }}
                                            tabIndex={-1}
                                            className={`scale-150 pl-2 pt-3 focus:outline-none ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`}
                                        >
                                            <PlusCircledIcon />
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className=" mt-5 flex w-full  items-center justify-center ">
                            <button
                                className="mx-2 w-52 rounded-full border-2 p-1 text-sm hover:border-yellow-400 sm:p-2  sm:text-base  "
                                onClick={handleUpdateFalse}
                            >
                                キャンセル
                            </button>
                            <button
                                type="submit"
                                className={`mx-2 w-52 rounded-full border-2  p-1 text-sm text-white  hover:bg-white  sm:p-2 sm:text-base  ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                            >
                                更新
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default UpdatePage;
