import React from 'react';
import { SurveyEntity } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import { convertUtcToJst16 } from '../../utils/DateTimeManager';
import { CopyIcon } from '@radix-ui/react-icons';
import { Tooltip } from 'react-tooltip';

interface ListPagePartsPCProps {
    surveys: SurveyEntity[];
    handleItemClick: (surveyId: string | undefined) => void;
    nowTime: string;
    handleButtonClick: (event: React.MouseEvent<HTMLInputElement>) => void;
    handleCopyClick: () => void;
    handleMouseOut: () => void;
    copy: string;
}

const ListPagePartsPC = ({
    surveys,
    handleItemClick,
    nowTime,
    handleButtonClick,
    handleCopyClick,
    handleMouseOut,
    copy,
}: ListPagePartsPCProps) => {
    return (
        <div className=" mt-12 hidden h-[95%] w-full overflow-y-auto rounded-xl bg-white shadow-lg sm:mt-10 sm:block">
            <table className={'w-full font-thin shadow-md'}>
                <thead className=" sticky top-0">
                    <tr className=" border-b-2 bg-white  text-left ">
                        <th className="px-4 py-2 "></th>
                        <th className=" py-2 pl-6 ">
                            <p>投票ID</p>
                        </th>

                        <th className=" py-2 pl-8">
                            <p>質問</p>
                        </th>
                        <th className=" py-2 pl-6">
                            <p>選択肢リスト</p>
                        </th>
                        <th className=" py-2 pl-6">
                            <p>説明</p>
                        </th>
                        <th className="py-2 pl-6">
                            <p>開始時間</p>
                        </th>
                        <th className="px-6 py-2">
                            <p>終了時間</p>
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {surveys
                        .sort((b, a) => {
                            if (a.createdAt === undefined) return 1;
                            if (b.createdAt === undefined) return -1;
                            return a.createdAt.localeCompare(b.createdAt);
                        })
                        .map((row: SurveyEntity) => (
                            //レコード
                            <tr
                                key={row.id}
                                onClick={() => handleItemClick(row.id)}
                                className=" cursor-pointer border-b-2 leading-relaxed hover:bg-yellow-50"
                            >
                                {/* 状態 */}
                                <td className="whitespace-nowrap py-1 pl-4 text-sm ">
                                    <div
                                        className={`rounded-full px-2 py-1 text-center font-bold shadow ${
                                            nowTime < convertUtcToJst16(row.startDateTime)
                                                ? 'bg-basecolor text-white'
                                                : convertUtcToJst16(row.endDateTime) < nowTime
                                                  ? 'bg-gray-400 text-white'
                                                  : 'bg-red-400 text-white'
                                        }`}
                                    >
                                        {nowTime < convertUtcToJst16(row.startDateTime)
                                            ? '期間前'
                                            : convertUtcToJst16(row.endDateTime) < nowTime
                                              ? '期間終了'
                                              : '期間中'}
                                    </div>
                                </td>
                                {/* 投票ID */}
                                <td>
                                    <div className="flex">
                                        <p className="ml-6 flex-auto ">{row.id}</p>
                                        <div
                                            onClick={handleButtonClick}
                                            className="  flex items-center justify-center"
                                        >
                                            <button
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        row.id || 'Default Text',
                                                    );
                                                    handleCopyClick();
                                                }}
                                                data-tooltip-id="copy"
                                                onMouseOut={handleMouseOut}
                                                className=""
                                            >
                                                <div className=" text-gray-400">
                                                    <CopyIcon />
                                                </div>
                                            </button>
                                            <Tooltip id="copy" place="top-end" content={copy} />
                                        </div>
                                    </div>
                                </td>
                                {/* 質問 */}
                                <td className="pl-8 ">
                                    <p>{row.questionBody}</p>
                                </td>
                                {/* 選択肢リスト */}
                                <td className="w-48 pl-12">
                                    <ol className="list-decimal">
                                        {Object.entries(row.options)
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0], undefined, {
                                                    numeric: true,
                                                }),
                                            )
                                            .map(([key, value]) => {
                                                return (
                                                    <p key={key}>
                                                        <li>{value}</li>
                                                    </p>
                                                );
                                            })}{' '}
                                    </ol>
                                </td>
                                {/* 説明 */}
                                <td className=" w-52 pl-6">
                                    <label>{row.description}</label>
                                </td>

                                {/* 開始時間～終了時間 */}
                                <td className="whitespace-nowrap pl-6">
                                    {convertUtcToJst16(row.startDateTime)
                                        .replace('T', ' ')
                                        .replace(/-/g, '/')}
                                </td>
                                <td className="whitespace-nowrap px-6">
                                    {convertUtcToJst16(row.endDateTime)
                                        .replace('T', ' ')
                                        .replace(/-/g, '/')}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListPagePartsPC;
