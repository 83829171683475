import React, { useEffect, useState } from 'react';
import { useAuth } from '../../features/auth/AuthContext';
import { useJWebApi } from '../../features/api/useJWebApi';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { GetPermissionsCommand } from '../../features/api/commands';
import { useLocation } from 'react-router-dom';
// import Modal from 'react-modal';
// import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

interface DropDownProps {
    admin: boolean;
    selectedSitecode: string;
    setSelectedSiteCode: React.Dispatch<React.SetStateAction<string>>;
}

const DropDown = ({ admin, selectedSitecode, setSelectedSiteCode }: DropDownProps) => {
    const { idToken, userId } = useAuth();
    const apiConfigure = new ApiConfigure({
        authToken: idToken,
    });

    const { response, call, error } = useJWebApi(apiConfigure);
    useEffect(() => {
        if (idToken) {
            call(
                new GetPermissionsCommand({
                    userId: userId,
                }),
            );
        }
    }, []);

    const handleSelectOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSiteCode(event.target.value);
    };

    //初期化
    const location = useLocation();
    const sitecodeState = location.state;
    if (!selectedSitecode) {
        const index = response?.data.sitecodes.findIndex((item: string) => item === sitecodeState);
        sitecodeState
            ? setSelectedSiteCode(response?.data.sitecodes[index])
            : setSelectedSiteCode(response?.data.sitecodes[0]);
    }

    //一つの場合は非表示
    const [sitecodelength, setSitecodelength] = useState(false);
    useEffect(() => {
        if (response) {
            if (response?.data.sitecodes.length !== 1) {
                setSitecodelength(true);
            } else {
                setSitecodelength(false);
            }
        }
    }, [response]);

    //エラーダイアログ
    // const [isErrorModal, setErrorModal] = useState(false);
    useEffect(() => {
        if (error?.errorMessage.includes('User is not found.')) {
            // setErrorModal(true);
            alert('Permissionが設定されていません。\n 管理者にお問い合わせください。');
        } else {
            // setErrorModal(false);
        }
    }, [error]);

    return (
        <>
            {/* <ErrorModal isErrorModal={isErrorModal} /> */}
            {sitecodelength && (
                <select
                    value={selectedSitecode}
                    onChange={handleSelectOption}
                    className={` block w-36 rounded-lg border-2 py-1 pl-2 focus:outline-none sm:w-80 sm:py-2 ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`}
                >
                    {response?.data.sitecodes.map((option: string) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            )}
        </>
    );
};

export default DropDown;

// interface ErrorModalProps {
//     isErrorModal: boolean;
// }
// const ErrorModal = ({ isErrorModal }: ErrorModalProps) => {
//     const customStyles = {
//         overlay: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             zIndex: '50',
//         },
//         content: {
//             top: '50%',
//             left: '50%',
//             right: 'auto',
//             bottom: 'auto',
//             marginRight: '-50%',
//             transform: 'translate(-50%, -50%)',
//             width: 'auto',
//             maxWidth: '90%',
//             height: 'auto',
//             maxHeight: '90%',
//             backgroundColor: '#ffa3a3',
//             borderRadius: '10px',
//             border: '4px solid #ef4444',
//         },
//     };
//     return (
//         <Modal
//             isOpen={isErrorModal}
//             contentLabel="errorModal"
//             style={customStyles}
//             ariaHideApp={false}
//         >
//             <div className="flex h-full w-full items-center justify-center p-8 text-red-500">
//                 <div>
//                     <div className=" flex text-xl font-bold">
//                         <div className="m-auto h-fit w-fit pr-2 pt-1">
//                             <ExclamationTriangleIcon className=" scale-150" />
//                         </div>
//                         <p>Permissionが設定されていません。</p>
//                     </div>
//                     <p className="pl-6 text-lg"> 管理者にお問い合わせください。</p>
//                 </div>
//             </div>
//         </Modal>
//     );
// };
