import { cdate } from 'cdate';

export const TWO_HOURS = 2 * 60 * 60 * 1000;
export const TWO_WEEKS = 14 * 24 * 60 * 60 * 1000;

export function calculateFutureTimeISO(baseDate: Date, millisecondsToAdd: number = 0): string {
  // 新しい日時を計算
  const futureTime = new Date(baseDate.getTime() + millisecondsToAdd);
  // console.table({
  //     baseDate: baseDate,
  //     millisecondsToAdd: millisecondsToAdd,
  //     futureTime: convertUtcToJst16(futureTime.toString()),
  // });
  // ISO8601形式の文字列に変換し、秒数以下を切り捨てる
  return convertUtcToJst16(futureTime.toString());
}

// Time
// JSTからUTCへの変換
export function convertJst16ToUtc(date: string): string {
  const dt = new Date(date);
  return cdate(dt).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

// UTCからJSTへの変換
export function convertUtcToJst16(date: string): string {
  const dt = new Date(date);
  // 'Asia/Tokyo';
  return cdate(dt).utcOffset(+9).format('YYYY-MM-DDTHH:mm');
}

// console.log(`JST to UTC: ${convertJst16ToUtc("2000-01-01T00:00")}`); // JST -> UTC (1999-12-31T15:00:00.000Z)
// console.log(`UTC to JST: ${convertUtcToJst16("2000-01-01T00:00:00Z")}`); // UTC -> JST (2000-01-01T09:00)
