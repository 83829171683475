import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../features/auth/AuthContext';
import TitleBar from '../../components/layouts/TitleBar';

interface SignInProps {
    admin: boolean;
}

const SignIn = ({ admin }: SignInProps) => {
    const inputStyle = `mx-auto mt-4 w-64 rounded-lg border-2 py-2 valid:pl-2 valid:text-base focus:bg-yellow-50 focus:outline-none lg:w-1/4 ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate(); // リダイレクトのためのhook

    const { isSignedIn, signIn } = useAuth();
    if (isSignedIn) {
        admin ? navigate('/admin') : navigate('/');
    }
    return (
        <div>
            <TitleBar admin={admin} />
            <div className="relative inset-0 flex items-center justify-center pt-24 lg:absolute lg:pt-0">
                <form>
                    <div className="flex w-screen flex-col text-lg">
                        <input
                            name="username"
                            type="text"
                            placeholder="ログインID"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className={inputStyle}
                        />
                        <input
                            name="password"
                            type="password"
                            placeholder="パスワード"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={inputStyle}
                        />
                        <button
                            onClick={async () => {
                                await signIn(username, password, admin, (path: string) => {
                                    navigate(path);
                                });
                            }}
                            className={`mx-auto mt-12 w-52 rounded-full border p-2 text-white hover:border hover:bg-white ${admin ? 'bg-adminbasecolor hover:border-adminbasecolor hover:text-adminbasecolor' : 'bg-basecolor hover:border-basecolor hover:text-basecolor'}`}
                        >
                            サインイン
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
