import { ZodError, z } from 'zod';

import { validateText, validateDateTime } from '../../../commons/CommonValidatator';

// まず期待する型を定義する
// "{option1: "text1", option2: "text2", option3: "text3", ... , optionN: "textN"}"
export type OptionKey = `option${number}`;
export type OptionsRecord = Record<OptionKey, string>;

// 正規表現で optionN という形式になっているか確認する式
export const RegExpOptionKey = new RegExp(/^option\d+$/);

export const convertOptionNumberKey = (key: string) => {
    // key が optionN の形になっているかチェック
    if (!RegExpOptionKey.test(key)) {
        throw Error(`key is not ${RegExpOptionKey}`);
    }
    // 型の精密化
    const optionKey = key as OptionKey;
    return optionKey;
};

//
// https://stackoverflow.com/questions/52869695/check-if-a-date-string-is-in-iso-and-utc-format
const isoDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
const ISODateSchema = z.string().refine((data) => isoDateRegex.test(data), {
    message: 'The string must be a valid ISO 8601 date format',
});

export interface SurveyEntity {
    id: string;
    sitecodes: string[];
    questionBody: string;
    description: string;
    options: OptionsRecord;
    allowMultiSelect: boolean;
    startDateTime: string;
    endDateTime: string;
    createdAt: string;
    updatedAt: string;
}
export interface UpdateSurveyEntity {
    id: string;
    allowMultiSelect: boolean;
    createdAt: string;
    updatedAt: string;
}

// 定義した型を基にバリデーターを作成
export class SurveysEntitySchemaValidator {
    // OptionsType の Zod スキーマを定義
    OptionsType = z.record(
        z.string().regex(
            // "{option1: "text1", option2: "text2", option3: "text3", ... , optionN: "textN"}"
            RegExpOptionKey,
        ),
        z.string().refine(
            (value) => {
                const validationResult = validateText(value);
                return validationResult === true;
            },
            (value) => {
                // validateText 関数からの返り値をエラーメッセージとして使用
                const validationResult = validateText(value);
                return {
                    message: validationResult as string,
                };
            },
        ),
    );

    // SurveyEntity の Zod スキーマを定義
    private SurveyEntitySchema = z
        .object({
            id: z.string(),
            sitecodes: z.array(z.string()).nonempty(),
            description: z.string().refine(
                (value) => {
                    const validationResult = validateText(value);
                    return validationResult === true;
                },
                (value) => {
                    // validateText 関数からの返り値をエラーメッセージとして使用
                    const validationResult = validateText(value);
                    return {
                        message: validationResult as string,
                    };
                },
            ),
            questionBody: z.string().refine(
                (value) => {
                    const validationResult = validateText(value);
                    return validationResult === true;
                },
                (value) => {
                    // validateText 関数からの返り値をエラーメッセージとして使用
                    const validationResult = validateText(value);
                    return {
                        message: validationResult as string,
                    };
                },
            ),
            options: this.OptionsType,
            allowMultiSelect: z.boolean(),
            startDateTime: ISODateSchema,
            endDateTime: ISODateSchema,
            createdAt: z.string(),
            updatedAt: z.string(),
        })
        .refine(
            (data) => {
                const isValidDateTime = validateDateTime(data.startDateTime, data.endDateTime);
                return isValidDateTime;
            },
            {
                message:
                    'startDateTime must be before endDateTime, and endDateTime must be between 2 hours and 2 weeks after startDateTime.',
            },
        );

    public validate(input: object): SurveyEntity {
        try {
            return this.SurveyEntitySchema.parse(input);
        } catch (error) {
            if (error instanceof ZodError) {
                console.error(error);
                throw error;
            } else {
                throw error;
            }
        }
    }
}
