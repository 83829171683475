import React from 'react';

interface TitleBarProps {
    admin: boolean;
}

const TitleBar = ({ admin }: TitleBarProps) => {
    return (
        <div
            className={`relative top-0 flex h-80 flex-col items-center justify-end py-0 lg:h-auto lg:justify-center lg:py-6 ${admin ? 'bg-adminbasecolor' : 'bg-basecolor'}`}
        >
            <div className="fixed top-2 z-50 rounded-lg bg-opacity-50 lg:relative lg:top-auto">
                <div className=" lg:y-0  z-50 my-2 flex w-full flex-col items-center  justify-center  lg:flex-row">
                    <img src="/survey2.png" alt="logo" className=" h-12" />
                    <p className="flex items-center pl-0 pt-2 text-4xl font-bold text-white lg:pl-4 lg:pt-0">
                        PICK ONE
                    </p>
                </div>
                <p className="z-50 mx-6 mt-5 text-sm text-white sm:mx-16 md:text-base lg:mx-0 lg:mt-4 xl:text-xl">
                    投票サービス"PICK ONE"を使うと、既に運営されているサイトに
                    <br className="hidden sm:block" />
                    質問して回答を集める機能を組み込むことができます。
                </p>
            </div>
            <img
                src="/top.png"
                alt="top"
                className="relative bottom-0 right-auto h-auto w-1/2  object-contain pt-4 sm:w-1/3 lg:absolute lg:right-10 lg:h-full lg:w-auto lg:pt-4 "
            />
        </div>
    );
};

export default TitleBar;
