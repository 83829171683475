/**
 *
 * @param text
 * @returns
 */
export const validateText = (text: string) => {
  const emojiRegex = /[\p{Emoji}]/gu;
  let match;
  const positions = [];

  while ((match = emojiRegex.exec(text)) !== null) {
    console.log(match, match[0]);
    // 正規表現 {Emoji} が半角の 0 ~ 9 を検出してしまう
    // (1️⃣ このような絵文字キーパッドの数字と関係ある？)
    if (/[0-9]/g.exec(match[0]) !== null) {
      // 0 ~ 9 は無視する
      continue;
    }
    const position = match.index + 1; // 検出位置 (0 スタートなので人間向けに 1 を加算する)
    positions.push(position);
  }

  if (positions.length > 0) {
    const positionsString = positions.join(", ");
    return `絵文字は含めないでください (${positionsString} 文字目)`;
  } else {
    return true;
  }
};

/**
 * バリデーションチェック
 * startDateTime は endDateTime よりも前でなければならない
 * endDateTime は startDateTime の2時間後から2週間後の間にある必要がある
 *
 * @param data
 * @returns
 */
export function validateDateTime(startDateTimeString: string, endDateTimeString: string) {
  const startDateTime = new Date(startDateTimeString);
  const endDateTime = new Date(endDateTimeString);

  if (startDateTime >= endDateTime) {
    return false;
  }

  const twoHoursLater = new Date(
    startDateTime.getTime() + 2 * 60 * 60 * 1000, // 2 時間
  );
  const twoWeeksLater = new Date(
    startDateTime.getTime() + 14 * 24 * 60 * 60 * 1000, // 2 週間
  );

  return endDateTime >= twoHoursLater && endDateTime <= twoWeeksLater;
}
